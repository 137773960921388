/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
import 'jquery-ui/ui/widgets/datepicker';
import 'daterangepicker/daterangepicker';
import 'lity/dist/lity.js';
import 'masonry-layout/dist/masonry.pkgd'

import Lightbox from 'bs5-lightbox';

$(document).ready(function () {

  document.querySelectorAll('.lightbox').forEach(el => el.addEventListener('click', (e) => {
    e.preventDefault();
    const lightbox = new Lightbox(el, {
      keyboard: true,
      size: 'xl'
    });
    lightbox.show();
  }));

  var $grid = $('.masonry').imagesLoaded(function () {
      $grid.masonry({
          itemSelector: '.grid-item',
          percentPosition: true,
          columnWidth: '.grid-sizer'
      });
  });

});

/**
 *  Datatables padrao
 **/
(function ($, dataTable) {

  // Datatable global configuration
  $.extend(true, dataTable.defaults, {
    language: {
      "sEmptyTable": "Nenhum registro encontrado",
      "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
      "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
      "sInfoFiltered": "(Filtrados de _MAX_ registros)",
      "sInfoPostFix": "",
      "sInfoThousands": ".",
      "sLengthMenu": "_MENU_ resultados por página",
      "sLoadingRecords": "Carregando...",
      "sProcessing": "Processando...",
      "sZeroRecords": "Nenhum registro encontrado",
      "sSearch": "Pesquisar",
      "oPaginate": {
        "sNext": "Próximo",
        "sPrevious": "Anterior",
        "sFirst": "Primeiro",
        "sLast": "Último"
      },
      "oAria": {
        "sSortAscending": ": Ordenar colunas de forma ascendente",
        "sSortDescending": ": Ordenar colunas de forma descendente"
      },
      "select": {
        "rows": {
          "_": "Selecionado %d linhas",
          "0": "Nenhuma linha selecionada",
          "1": "Selecionado 1 linha"
        }
      },
      "buttons": {
        "copy": "Copiar para a área de transferência",
        "copyTitle": "Cópia bem sucedida",
        "copySuccess": {
          "1": "Uma linha copiada com sucesso",
          "_": "%d linhas copiadas com sucesso"
        }
      }
    },
    aLengthMenu: [20, 50, 100, 200],
    iDisplayLength: 20,
  });

})(jQuery, jQuery.fn.dataTable);

$(document).ready(function () {
  
  /**
   * Remove acentos na busca do DataTable
   */
  var searchType = jQuery.fn.DataTable.ext.type.search;
  searchType.string = function (data) {
    return !data ?
      '' :
      typeof data === 'string' ?
        removeAccents(data) :
        data;
  };
  searchType.html = function (data) {
    return !data ?
      '' :
      typeof data === 'string' ?
        removeAccents(data.replace(/<.*?>/g, '')) :
        data;
  };

  function removeAccents(data) {
    return data
      .replace(/έ/g, 'ε')
      .replace(/[ύϋΰ]/g, 'υ')
      .replace(/ό/g, 'ο')
      .replace(/ώ/g, 'ω')
      .replace(/ά/g, 'α')
      .replace(/[ίϊΐ]/g, 'ι')
      .replace(/ή/g, 'η')
      .replace(/\n/g, ' ')
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ê/g, 'e')
      .replace(/î/g, 'i')
      .replace(/ô/g, 'o')
      .replace(/è/g, 'e')
      .replace(/ï/g, 'i')
      .replace(/ü/g, 'u')
      .replace(/ã/g, 'a')
      .replace(/õ/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/ì/g, 'i');
  }

  /**
   * Datatables simples
   */
  $('.dataTable-list').DataTable({
    "order": [[0, 'desc']],
    'responsive': true,
    'autoWidth': false
  })

  /**
   * Upload File
   */
  $('.formUpload').each(function () {

    var form = $(this);
    var formUpload = form.ajaxForm({
      beforeSend: function () {
        console.log('dispare');
        console.log(form.find('#field').val());

        $(formUpload).find('.progress-bar').text('0%');
        $(formUpload).find('.progress-bar').css('width', '0%');
      },
      uploadProgress: function (event, position, total, percentComplete) {
        if (percentComplete < 100) {
          $(formUpload).find('.progress-bar').text(percentComplete + '%');
        } else {
          $(formUpload).find('.progress-bar').text('Finalizando, aguarde...');
        }
        $(formUpload).find('.progress-bar').css('width', percentComplete + '%');
      },
      success: function (data) {
        // console.log(data);
        if (data.success) {
          $(formUpload).find('#success').val(data.file);
          $("input[name=" + form.find('#field').val() + "]").val(data.file);
          $(formUpload).find('.progress-bar').html('<a href="' + data.url + '" target="_blank" class="text-white">Arquivo <b>' + data.name + '</b> enviado com sucesso!</a>');
          $(formUpload).find('.progress-bar').css('width', '100%');
        } else {
          $(formUpload).find('.progress-bar').text(data.error);
        }

      },
      error: function (data) {
        console.log(data);
        console.log('Tá por aqui');
      }
    });

  });

  $('#password').on('change', function () {
    password_confirmation();
  });

  $('#password_confirmation').on('change', function () {
    password_confirmation();
  });

  function password_confirmation() {
    if ($('#password').val() != $('#password_confirmation').val()) {
      if ($('#password').val() != '') {
        $('#password_error').html('Os campos não estão idênticos.');
        $('#password_error').addClass('d-block');
        $('#password').addClass('is-invalid');
        $('#password_confirmation').addClass('is-invalid');
        return false;
      }
    }
    $('#password_error').html('');
    $('#password_error').removeClass('d-block');
    $('#password').removeClass('is-invalid');
    $('#password_confirmation').removeClass('is-invalid');
    return true;
  }

  /** Datepicker */
  $.datepicker.regional['pt_BR'] = {
    closeText: 'Fechar',
    prevText: '&#x3c;Anterior',
    nextText: 'Pr&oacute;ximo&#x3e;',
    currentText: 'Hoje',
    monthNames: ['Janeiro', 'Fevereiro', 'Mar&ccedil;o', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    dayNames: ['Domingo', 'Segunda-feira', 'Ter&ccedil;a-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    weekHeader: 'Sm',
    dateFormat: 'dd/mm/yy',
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };

  $.datepicker.setDefaults($.datepicker.regional['pt_BR']);
  $('.datepicker').datepicker();

  $("#txtdate").datepicker({
    showOn: "button",
    buttonText: "Selecionar data"
  });

  /** Datetimepicker */
  jQuery.datetimepicker.setLocale('pt-BR');

  jQuery('.datetimepicker').datetimepicker({
    format: 'd/m/Y H:i:s'
  });

  /** Rangepicker */
  $('.rangepicker').daterangepicker({
    "autoUpdateInput": false,
    "autoApply": true,
    "locale": {
      "format": "DD/MM/YYYY",
      "separator": " - ",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"
      ],
      "monthNames": [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ]
    }
  }, function (start_date, end_date) {
    this.element.val(start_date.format("DD/MM/YYYY") + ' - ' + end_date.format("DD/MM/YYYY"));
  });

  /**
   * Preenchimento automático de formulários com consulta de CEP
   */
  //Quando o campo cep perde o foco.
  $("#postcode").blur(function () {

    //Nova variável "cep" somente com dígitos.
    var cep = $(this).val().replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep != "") {

      //Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;

      //Valida o formato do CEP.
      if (validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        $("#street").val("...");
        $("#number").val("...");
        $("#district").val("...");
        $("#complemet").val("...");
        $("#city").val("...");

        //Consulta o webservice viacep.com.br/
        $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {

          if (!("erro" in dados)) {
            //Atualiza os campos com os valores da consulta.
            $("#street").val(dados.logradouro);
            $("#number").val('');
            $("#district").val(dados.bairro);
            $("#complement").val('');
            $("#city").val(dados.localidade);
            $("select#state").val(dados.uf);
          } //end if.
          else {
            //CEP pesquisado não foi encontrado.
            clear_form_postcode();
            alert("CEP não encontrado.");
          }
        });
      } //end if.
      else {
        //cep é inválido.
        clear_form_postcode();
        alert("Formato de CEP inválido.");
      }
    } //end if.
    else {
      //cep sem valor, limpa formulário.
      clear_form_postcode();
    }
  });

  /**
   * Função de máscara para telefones com DDD
   * @param val
   * @returns {string}
   */
  var maskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
    options = {
      onKeyPress: function (val, e, field, options) {
        field.mask(maskBehavior.apply({}, arguments), options);
      }
    };

  /**
   * Máscaras para preenchimento de formulários mais comuns
   */
  $('.mask-date').mask('99/99/9999');
  $('.mask-time').mask('99:99:99');
  $('.mask-date_time').mask('99/99/9999 99:99:99');
  $('.mask-cpf').mask('000.000.000-00', { reverse: true });
  $('.mask-cnpj').mask('00.000.000/0000-00', { reverse: true });
  $('.mask-money').mask('###.###.###,##', { reverse: true });
  $('.mask-postcode').mask('99999-999');
  $('.mask-birth').mask('99/99');
  $('.mask-phone').mask(maskBehavior, options);
  $('.mask-number').mask('############################0');

  /**
   * Função para limpar o formulário com cep em branco
   */
  var clear_form_postcode = function () {
    $("#street").val("");
    $("#number").val("");
    $("#district").val("");
    $("#complement").val("");
    $("#city").val("");
    $("#state").val("");
  }

  /**
   * Bootstrap Select
   */
  // $('.bt-select').selectpicker({
  //   noneSelectedText: 'Nenhum',
  //   deselectAllText: 'Nenhum',
  //   selectAllText: 'Todos',
  //   noneResultsText: 'Nenhum resultado encontrado {0}'
  // });

});

//Função para formatar o valor em moeda
function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

//Carrousel Galery
$(document).ready(function () {

  let items = document.querySelectorAll('.carousel-gallery .carousel-item')

  items.forEach((el) => {
      const minPerSlide = 4
      let next = el.nextElementSibling
      for (var i=1; i<minPerSlide; i++) {
          if (!next) {
              // wrap carousel by using first child
              next = items[0]
          }
          let cloneChild = next.cloneNode(true)
          el.appendChild(cloneChild.children[0])
          next = next.nextElementSibling
      }
  })
});